import { FETCH_TESTIMONIALS_DATA } from '../constants/ActionTypes'
import { TESTIMONIALS_DATA_URL } from '../constants/DataUrls'

export function fetchTestimonialsData() {
    return dispatch => {
        fetch(TESTIMONIALS_DATA_URL)
            .then(data => data.json())
            .then(data => {
                dispatch({
                    type: FETCH_TESTIMONIALS_DATA,
                    data
                })
            })
    }
}