import { FETCH_PHOTO_DATA } from '../constants/ActionTypes'
import { PHOTO_DATA_URL } from '../constants/DataUrls'

export function fetchPhotoData() {
    return dispatch => {
        fetch(PHOTO_DATA_URL)
            .then(data => data.json())
            .then(data => data.reverse())
            .then(data => {
                dispatch({
                    type: FETCH_PHOTO_DATA,
                    data
                })
            })
    }
}