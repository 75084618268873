import React from 'react'

import Header from '../components/Header'
import Footer from '../containers/Footer'

import PhotoListContainer from '../containers/PhotoListContainer'

const PhotoListScreen = props => (
    <React.Fragment>
        <Header />
        <div className="photo photo-list">
            <div className="top">
                <h3 className="title">Photo Portfolio</h3>
            </div>
            <PhotoListContainer />
        </div>
        <Footer />
    </React.Fragment>
)

export default PhotoListScreen