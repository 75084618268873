import React from 'react'
import { MediaQuery } from 'react-responsive'
import Instagram from '../components/Instagram'
import Menu from '../components/Menu'
import MobileMenu from '../components/MobileMenu'
import Testimonials from '../containers/Testimonials'

import HomeHeader from '../containers/HomeHeader'
import Footer from '../containers/Footer'
import FullWidthVideo from '../containers/FullWidthVideo'
import Gallery from '../containers/Gallery'

import { RESPONSIVE_BREAKPOINT_MEDIUM } from '../constants/ResponsiveBreakpoints'

const Home = () => (
    <React.Fragment>
        <HomeHeader />

        <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <Menu isSection />
        </MediaQuery>

        <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <MobileMenu />
        </MediaQuery>

        <Gallery />
        <Testimonials />
        <FullWidthVideo />

        <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <Instagram />
        </MediaQuery>

        <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <Instagram images="3" />
        </MediaQuery>

        <Footer />
    </React.Fragment>
)

export default Home