import { FETCH_TESTIMONIALS_DATA } from '../constants/ActionTypes'

const initialState = {
    data: []
}

const testimonials = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TESTIMONIALS_DATA:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default testimonials