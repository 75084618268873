import { IMAGE_THUMBNAIL } from '../constants/ImageType'

function directory(photoSlug, type) {
    if (type !== IMAGE_THUMBNAIL) {
        return `/images/photos/${photoSlug}/default/`    
    }
    
    return `/images/photos/${photoSlug}/thumbnails/`
}

function url(photoSlug, type, photo) {
    return `${directory(photoSlug, type)}${photo}`
}

export {
    directory,
    url
}