import React from 'react'
import { MediaQuery } from 'react-responsive'
import Slider from './Slider'
import { RESPONSIVE_BREAKPOINT_LARGE, RESPONSIVE_BREAKPOINT_MEDIUM } from '../constants/ResponsiveBreakpoints'

class Gallery extends React.Component {

    render() {
        return (
            <div className="gallery">
                <div className="top">
                    <h3 className="title">gallery</h3>
                </div>

                <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_LARGE}>
                    <Slider data={this.props.data} slidesCount="3" />
                </MediaQuery>
                <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_MEDIUM} maxWidth={RESPONSIVE_BREAKPOINT_LARGE}>
                    <Slider data={this.props.data} slidesCount="2" />
                </MediaQuery>
                <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
                    <Slider data={this.props.data} slidesCount="1" />
                </MediaQuery>
            </div>
        )
    }
}

export default Gallery