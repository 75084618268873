import React from 'react'
import Translate from '../containers/Translate'

class Instagram extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            posts: []
        }
    }

    componentDidMount() {
        if(this.state.posts.length === 0) {
            fetch('https://www.instagram.com/emi.and.tabita/?__a=1')
            .then(response => response.json())
            .then(data => {
                const imagesCount = +this.props.images || 6
                const posts = data.graphql.user.edge_owner_to_timeline_media.edges.splice(0, imagesCount)

                this.setState({
                    posts: posts
                })
            })
            .catch(err => console.log('err ', err))
        }
    }

    render() {
        const { posts } = this.state

        if(posts.length === 0) {
            return null
        }

        return (
            <div className="instagram">
                <div className="top">
                    <h3 className="title">
                        <Translate value="instagram_title" />
                    </h3>
                </div>
                <div className="row">
                    {posts.map((post, index) => {
                        const style = {
                            backgroundImage: `url('${post.node.thumbnail_src}')`
                        }
                        return (
                            <a className="thumbnail" key={index} href="https://www.instagram.com/emi.and.tabita" target="_blank" rel="noopener noreferrer">
                                <div className="thumbnail-image" style={style}></div>
                            </a>
                        )
                    })}
                </div>
            </div>
        )
    }
   
}

export default Instagram