import React from 'react'

const Icon = props => {
    const type = props.type || ''

    if (type === "close") {
        return <div className="icon-close"></div>
    }

    if (type === "menu") {
        return <div className="icon-menu"></div>
    }

    return null
}
export default Icon