import { FETCH_VIDEO_DATA } from '../constants/ActionTypes'

const initialState = {
    data: []
}

const video = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VIDEO_DATA:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default video