import React from 'react'
import BlinkingArrow from './BlinkingArrow';

class HomeHeader extends React.Component {
    changeLanguage(language) {
        this.props.changeLanguage(language)
    }

    render() {
        return (
            <div className="home-header">
                <div className="background" />
                <div className="foreground">
                    <div>
                        <div className="language-links">
                            <span onClick={this.changeLanguage.bind(this, 'ro')}>RO</span> / <span onClick={this.changeLanguage.bind(this, 'en')}>EN</span>
                        </div>
                    </div>
                    <div className="brand">
                        <h1>Emi And Tabita</h1>
                        <h2>FINE ART PHOTOGRAPHER AND VIDEOGRAPHER</h2>
                    </div>
                    <BlinkingArrow />
                </div>
            </div>
        )
    }
}

export default HomeHeader