import React from 'react'
import translation from '../constants/Translation'

class Translate extends React.Component {
    render() {
        const { language, value } = this.props

        if (!language || !value) {
            return null
        }

        return translation[language][value] || ""
    }
}

export default Translate