import React from 'react'
import { Link } from 'react-router-dom'
import Translate from '../containers/Translate'
import Icon from './Icon'

class MobileMenu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false
        }

        this.onToggleClick = this.onToggleClick.bind(this)
    }

    onToggleClick() {
        this.setState(state => ({
            isVisible: !state.isVisible
        }))
    }

    render() {
        return (
            <div className="mobile-menu">
                <div onClick={this.onToggleClick} className={`toggle-button ${this.state.isVisible ? 'toggle-button-visible' : ''}`}>
                    {!this.state.isVisible && <Icon type="menu" />}
                    {this.state.isVisible && <Icon type="close" />}
                </div>
                {this.renderMenuContent()}
            </div>
        )
    }

    renderMenuContent() {
        if (!this.state.isVisible) {
            return null
        }

        return (
            <div className="mobile-menu-content">
                <h1>Emi And Tabita</h1>
                <div className="links">
                    <Link to="/"><Translate value="menu_home" /></Link>
                    <Link to="/about"><Translate value="menu_about" /></Link>
                    <Link to="/photo"><Translate value="menu_photo" /></Link>
                    <Link to="/video"><Translate value="menu_video" /></Link>
                    <Link to=""><Translate value="menu_blog" /></Link>
                    <Link to="/contact"><Translate value="menu_contact" /></Link>

                    <div className="icons">
                        <a href="https://www.instagram.com/emi.and.tabita/" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.facebook.com/emiandtabita/" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook-official" aria-hidden="true"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCZmD276oV11_t7jKAc3D2CA" target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-youtube" aria-hidden="true"></i>
                        </a>
                        <a href="mailto:emiandtabita@gmail.com">
                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileMenu