import React from 'react'
import translate from '../utils/translate'
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { EMAIL_SCRIPT } from '../constants/Urls'

const TOASTY_CONFIG = {
    duration: 5000,
    position: 'top-right'
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                'groom-and-bride': '',
                'wedding-date': '',
                'wedding-place': '',
                'email': '',
                'phone': '',
                'about-us': '',
                'message': ''
            },
            submitted: false
        }

        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleOnChange(event) {
        const data = {...this.state.data}
        data[event.target.name] = event.target.value
        this.setState({ data })
    }

    handleSubmit(event) {
        event.preventDefault()

        this.setState({
            submitted: true
        })

        const language = this.props.language || 'en'
        const data = this.state.data
        const isValidForm = data['groom-and-bride'] && data['wedding-date'] && data['wedding-place'] && data['email'] && data['phone'] && data['about-us'] && data['message']

        if (!isValidForm) {
            toaster.notify(translate(language, 'contact_error'), TOASTY_CONFIG);
            return
        }

        fetch(EMAIL_SCRIPT, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.data)
        })
        .then(response => response.text())
        .then(response => {
            if (response !== 'SUCCESS') {
                throw new Error('error')
            }

            toaster.notify(translate(language, 'contact_success'), TOASTY_CONFIG);
    
            const stateData = {...this.state.data}
            stateData['groom-and-bride'] = ''
            stateData['wedding-date'] = ''
            stateData['wedding-place'] = ''
            stateData['email'] = ''
            stateData['phone'] = ''
            stateData['about-us'] = ''
            stateData['message'] = ''
            this.setState({ submitted: false, data: stateData })
        }).catch(err => {
            toaster.notify(translate(language, 'contact_submit_error'), TOASTY_CONFIG);
        });
    }

    render() {
        const language = this.props.language || 'en'

        const data = this.state.data

        return (
            <div className="contact-form-page">

                <div className="contact-form">
                    <div className="contact-form-background">
                        <div className="contact-form-background-color" />
                    </div>
                    <div className="contact-form-details">
                        <form onSubmit={this.handleSubmit}>
                            <div className="top">
                                <h3 className="title">contact</h3>
                            </div>
                            <div className="row">
                                <input type="text" className={this.state.submitted && !data['groom-and-bride'] ? "invalid" : ""} value={data['groom-and-bride']} onChange={this.handleOnChange} name="groom-and-bride" placeholder={translate(language, 'contact_groom_and_bride')} />
                                <input type="text" className={this.state.submitted && !data['wedding-date'] ? "invalid" : ""} value={data['wedding-date']} onChange={this.handleOnChange} name="wedding-date" placeholder={translate(language, 'contact_wedding_date')} />
                            </div>
                            <div className="row">
                                <input type="text" className={this.state.submitted && !data['wedding-place'] ? "invalid" : ""} value={data['wedding-place']} onChange={this.handleOnChange} name="wedding-place" placeholder={translate(language, 'contact_wedding_venue')} />
                                <input type="text" className={this.state.submitted && !data['email'] ? "invalid" : ""} value={data['email']} onChange={this.handleOnChange} name="email" placeholder={translate(language, 'contact_email')} />
                            </div>
                            <div className="row">
                                <input type="text" className={this.state.submitted && !data['phone'] ? "invalid" : ""} value={data['phone']} onChange={this.handleOnChange} name="phone" placeholder={translate(language, 'contact_phone')} />
                                <input type="text" className={this.state.submitted && !data['about-us'] ? "invalid" : ""}value={data['about-us']} onChange={this.handleOnChange} name="about-us" placeholder={translate(language, 'contact_about_us')} />
                            </div>
                            <div className="row">
                                <textarea onChange={this.handleOnChange} className={this.state.submitted && !data['message'] ? "invalid" : ""} name="message" value={data['message']} placeholder={translate(language, 'contact_details')} />
                            </div>
                            <div className="row">
                                <p><small>{translate(language, 'contact_mandatory')}</small></p>
                            </div>
                            <div className="row submit-row">
                                <button type="submit" className="small transparent">{translate(language, 'contact_submit')}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}



export default ContactForm