import { connect } from 'react-redux'
import ResourceList from '../components/ResourceList'

const mapStateToProps = state => {
    return {
        data: state.photo.data,
        resourceUrlPrefix: '/photo'
    }
}

export default connect(mapStateToProps)(ResourceList)