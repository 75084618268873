import translation from '../constants/Translation'

function translate(language, value) {
    if (!language || !value) {
        return null
    }

    return translation[language][value] || ""
}

export default translate