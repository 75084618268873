import React from 'react'
import { MediaQuery } from 'react-responsive'
import Menu from './Menu'
import MobileMenu from '../components/MobileMenu'
import { RESPONSIVE_BREAKPOINT_MEDIUM } from '../constants/ResponsiveBreakpoints'

const Header = () => (
    <header>
        <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <Menu showBrand />
        </MediaQuery>

        <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <MobileMenu />
        </MediaQuery>
    </header>
)

export default Header