import React from 'react'
import Header from '../components/Header'
import BehindTheLens from '../components/BehindTheLens'
import Footer from '../containers/Footer'
import Testimonials from '../containers/Testimonials'

const About = () => (
    <React.Fragment>
        <Header />
        <BehindTheLens />
        <Testimonials />
        <Footer />
    </React.Fragment>
)

export default About