import { connect } from 'react-redux'
import VideoList from '../components/VideoList'

const mapStateToProps = state => {
    return {
        data: state.video.data,
        resourceUrlPrefix: '/video'
    }
}

export default connect(mapStateToProps)(VideoList)