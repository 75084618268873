import { FETCH_PHOTO_DATA } from '../constants/ActionTypes'

const initialState = {
    data: []
}

const photo = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PHOTO_DATA:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default photo