import React from 'react'
import { Link } from 'react-router-dom'

class Slider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            index: 0,
            slidesCount: +this.props.slidesCount || 3
        }

        this.onNextClick = this.onNextClick.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
    }

    onNextClick() {
        if (this.state.index + this.state.slidesCount <= this.props.data.length - 1) {
            this.setState(state => ({
                index: state.index + this.state.slidesCount
            }))
        }
    }

    onPreviousClick() {
        if (this.state.index - this.state.slidesCount >= 0) {
            this.setState(state => ({
                index: state.index - this.state.slidesCount
            }))
        }
    }

    render() {
        if (!this.props.data) {
            return null
        }

        return (
            <div className="slider">
                <div className="left" onClick={this.onPreviousClick}>
                    <i className="arrow arrow-left" />
                </div>
                {this.renderSlides()}
                <div className="right" onClick={this.onNextClick}>
                    <i className="arrow arrow-right" />
                </div>
            </div>
        )
    }

    renderSlides() {
        const slides = JSON.parse(JSON.stringify(this.props.data))
        const activeSlides = slides.splice(this.state.index, this.state.slidesCount)

        return (
            <div className="slides">
                {activeSlides.map(slide => {
                    const { id, title, subtitle, thumbnail, slug } = slide

                    const style = {
                        backgroundImage: `url('${thumbnail}')`
                    }

                    return (
                        <Link key={id} to={`photo/${slug}`} className="slide">
                            <div className="background" style={style} />
                            <div className="foreground">
                                <h4>{title}</h4>
                                <h5>{subtitle}</h5>
                            </div>
                        </Link>
                    )
                })}

                {activeSlides.length < this.state.slidesCount && this.renderFakeSlides(this.state.slidesCount - activeSlides.length)}
            </div>
        )
    }

    renderFakeSlides(count) {
        const fakeSlides = []
        for (let i = 0; i < count; i++) {
            fakeSlides.push(<div key={i} className="fake-slide" />)
        }
        return fakeSlides
    }
}

export default Slider