import React from 'react'
import { MediaQuery } from 'react-responsive'
import { RESPONSIVE_BREAKPOINT_MEDIUM } from '../constants/ResponsiveBreakpoints'

import Header from '../components/Header'
import Instagram from '../components/Instagram'
import ContactForm from '../containers/ContactForm'

import Footer from '../containers/Footer'

const Contact = () => (
    <React.Fragment>
        <Header />
        <ContactForm />

        <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <Instagram />
        </MediaQuery>

        <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
            <Instagram images="3" />
        </MediaQuery>

        <Footer />
    </React.Fragment>
)

export default Contact