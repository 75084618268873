import React from 'react'
import { Link } from 'react-router-dom'
import Translate from '../containers/Translate'

class FullWidthVideo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            windowWidth: 0,
            windowHeight: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    render() {
        if (!this.props.data) {
            return null
        }

        const { videoId } = this.props.data
        // const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&modestbranding=1&controls=0&fs=0&hl=0&loop=1&rel=0`
        const videoUrl = `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1&mute=1&modestbranding=1&version=3&enablejsapi=1&html5=1&hd=1&wmode=opaque&showinfo=0&rel=0;&end=0;origin=https://emiandtabita.com;&controls=0&playsinline=1&end=0`

        const style = this.calculateIFrameDimensions()

        return (
            <>
                <div className="full-width-video">
                    <iframe title="youtube-video" src={videoUrl} style={style} allow="autoplay" allowFullScreen></iframe>
                </div>
                <div className="full-width-link">
                    <Link to="/video">
                        <Translate value="full_width_link_label" />
                    </Link>
                </div>
            </>
        )
    }

    calculateIFrameDimensions() {
        let iframeHeight = this.state.windowHeight + 400
        let iframeWidth = iframeHeight * 16 / 9

        if (iframeWidth > this.state.windowWidth) {
            const left = -(iframeWidth - this.state.windowWidth) / 2
            const top = -200

            return {
                height: iframeHeight,
                width: iframeWidth,
                left,
                top
            }
        }

        iframeWidth = this.state.windowWidth
        iframeHeight = iframeWidth * 9 / 16 + 400
        const top = -(iframeHeight - this.state.windowHeight) / 2

        return {
            width: iframeWidth,
            height: iframeHeight,
            top
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        });
    }
}

export default FullWidthVideo