import { combineReducers } from 'redux'
import language from './language'
import photo from './photo'
import testimonials from './testimonials'
import video from './video'

const reducers = combineReducers({
    language,
    photo,
    testimonials,
    video
})

export default reducers