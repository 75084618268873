import { CHANGE_LANGUAGE } from '../constants/ActionTypes'
import { LOCAL_STORAGE_LANGUAGE_KEY } from '../constants/LocalStorage'

export function changeLanguage(language) {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, language)

    return dispatch => {
        dispatch({
            type: CHANGE_LANGUAGE,
            language
        })
    }
}