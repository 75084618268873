import React from 'react'
import VideoModal from './VideoModal'

class VideoList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isModalVisible: false,
            selectedVideoId: null
        }

        this.onSelectVideo = this.onSelectVideo.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
    }

    selectVideo(resourceId) {
        this.setState({
            isModalVisible: true,
            selectedVideoId: resourceId
        })
    }

    onSelectVideo(data) {
        this.setState({
            selectedVideoId: data.id
        })
    }

    onCloseModal() {
        this.setState({
            isModalVisible: false,
            selectedVideoId: null
        })
    }

    render() {
        const { data } = this.props

        if (!data || !data.length) {
            return null
        }

        return (
            <React.Fragment>
                <div className="slides">
                    {data && data.length && data.map(resource => {
                        const style = {
                            backgroundImage: `url('${resource.thumbnail}')`
                        }

                        return (
                            <div onClick={this.selectVideo.bind(this, resource.id)} key={resource.id} className="slide video-list-slide">
                                <div className="background" style={style} />
                                <div className="foreground">
                                    <h4>{resource.title}</h4>
                                    <h5>{resource.subtitle}</h5>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {this.renderModal()}
            </React.Fragment>
        )
    }

    renderModal() {
        const { isModalVisible, selectedVideoId } = this.state
        if (isModalVisible && selectedVideoId) {
            return <VideoModal id={selectedVideoId} onCloseModal={this.onCloseModal} data={this.props.data} onSelectVideo={this.onSelectVideo} title="Hello" />
        }

        return null
    }
}

export default VideoList