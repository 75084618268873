
import { connect } from 'react-redux'
import Translate from '../components/Translate'

const mapStateToProps = (state, ownProps) => {
    return {
        language: state.language.language,
        value: ownProps.value
    }
}

export default connect(mapStateToProps)(Translate)