import React from 'react'
import GallerySlider from './GallerySlider'

import { MediaQuery } from 'react-responsive'
import { RESPONSIVE_BREAKPOINT_LARGE, RESPONSIVE_BREAKPOINT_MEDIUM, RESPONSIVE_BREAKPOINT_SMALL, RESPONSIVE_BREAKPOINT_XSMALL } from '../constants/ResponsiveBreakpoints'

const Photo = props => {
    if (!props.data) {
        return null
    }

    const { title, slug, photos } = props.data

    console.log(props.data)

    return (
        <div className="photo">
            <div className="top">
                <h3 className="title">{title}</h3>
            </div>

            <MediaQuery minWidth={RESPONSIVE_BREAKPOINT_LARGE}>
                <GallerySlider slug={slug} photos={photos} sliderWidth={1000}/>
            </MediaQuery>

            <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_LARGE} minWidth={RESPONSIVE_BREAKPOINT_MEDIUM}>
                <GallerySlider slug={slug} photos={photos} sliderWidth={800}/>
            </MediaQuery>

            <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_MEDIUM} minWidth={RESPONSIVE_BREAKPOINT_SMALL}>
                <GallerySlider slug={slug} photos={photos} sliderWidth={500}/>
            </MediaQuery>

            <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_SMALL} minWidth={RESPONSIVE_BREAKPOINT_XSMALL}>
                <GallerySlider slug={slug} photos={photos} sliderWidth={400}/>
            </MediaQuery>

            <MediaQuery maxWidth={RESPONSIVE_BREAKPOINT_XSMALL}>
                <GallerySlider slug={slug} photos={photos} sliderWidth={300}/>
            </MediaQuery>
        </div>
    )
}

export default Photo