import { CHANGE_LANGUAGE } from '../constants/ActionTypes'
import { LOCAL_STORAGE_LANGUAGE_KEY } from '../constants/LocalStorage'

const languageValue = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || 'ro'

const initialState = {
    language: languageValue
}

const language = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.language
            }
        default:
            return state
    }
}

export default language