import { FETCH_VIDEO_DATA } from '../constants/ActionTypes'
import { VIDEO_DATA_URL } from '../constants/DataUrls'

export function fetchVideoData() {
    return dispatch => {
        fetch(VIDEO_DATA_URL)
            .then(data => data.json())
            .then(data => data.reverse())
            .then(data => {
                dispatch({
                    type: FETCH_VIDEO_DATA,
                    data
                })
            })
    }
}