import React from 'react'

class YoutubePlayer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            windowWidth: 0,
            windowHeight: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    render() {
        const { id } = this.props
        if (!id) {
            return null
        }

        const videoUrl = `https://www.youtube.com/embed/${id}?autoplay=1&modestbranding=0`
        return (
            <div className="youtube-player-container">
                <iframe style={this.calculateIFrameDimensions()} title="youtube-video" src={videoUrl} allow="autoplay" allowFullScreen></iframe>
            </div>
        )
    }

    calculateIFrameDimensions() {
        const topModalHeight = 60
        const bottomModalHeight = 180
        const verticalPadding = 180

        const windowWidth = this.state.windowWidth
        const windowHeight = this.state.windowHeight - topModalHeight - bottomModalHeight - verticalPadding

        if (windowHeight < windowWidth * 9 / 16) {
            return {
                height: windowHeight,
                width: 16 * windowHeight / 9
            }
        }

        return {
            width: windowWidth,
            height: windowWidth * 9 / 16
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        });
    }
}
export default YoutubePlayer