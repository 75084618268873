import React from 'react'

import Header from '../components/Header'
import Footer from '../containers/Footer'

import VideoListContainer from '../containers/VideoListContainer'

const VideoListScreen = props => (
    <React.Fragment>
        <Header />
        <div className="photo video photo-list">
            <div className="top">
                <h3 className="title">Video Portfolio</h3>
            </div>

            <VideoListContainer />
        </div>
        <Footer />
    </React.Fragment>
)

export default VideoListScreen