import React from 'react'

const YoutubeThumbnail = props => {
    const { url, title, subtitle, onClick } = props

    const style = {
        backgroundImage: `url(${url})`
    }

    return (
        <div className="youtube-thumbnail" onClick={onClick} style={style}>
            <div className="youtube-thumbnail-content">
                <div className="thumbnail-background" />
                <div className="thumbnail-foreground">
                    <h3>{title}</h3>
                    <p>{subtitle}</p>
                </div>
            </div>
        </div>
    )
}

export default YoutubeThumbnail