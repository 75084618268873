import { connect } from 'react-redux'
import FullWidthVideo from '../components/FullWidthVideo'

const mapStateToProps = state => {
    const video = state.video.data.find(video => video.isFeatured)
    if (!video) {
        return {
            data: state.video.data[0]
        }
    }

    return {
        data: video
    }
}

export default connect(mapStateToProps)(FullWidthVideo)