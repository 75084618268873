import React from 'react'

class Testimonials extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            active: 0
        }

        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.onNextClick = this.onNextClick.bind(this)
    }

    onPreviousClick() {
        this.setState(state => {
            return {
                active: state.active === 0 ? this.props.data.length - 1 : state.active - 1
            }
        })
    }

    onNextClick() {
        this.setState(state => {
            return {
                active: state.active >= this.props.data.length - 1 ? 0 : state.active + 1
            }
        })
    }

    render() {
        return (
            <div className="testimonials">
                <div className="top">
                    <h3 className="title">testimonials</h3>
                </div>
                <div className="content">
                    <div className="left" onClick={this.onPreviousClick}>
                        <i className="arrow arrow-left" />
                    </div>
                    <div className="row">
                        {this.props.data.map((testimonial, index) => {
                            const { title, content, image } = testimonial
                            const className = this.state.active !== index ? 'hidden' : 'visible'
                            
                            return (
                                <div key={index} className={`testimonial ${className}`}>
                                    <div className="text">
                                        <h4>{title}</h4>
                                        <p>{content}</p>
                                    </div>
                                    <div className="image">
                                        <img src={image} alt="" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="right" onClick={this.onNextClick}>
                        <i className="arrow arrow-right" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Testimonials