import React from 'react'
import YoutubeThumbnail from './YoutubeThumbnail'

class YoutubeThumbnailList extends React.Component {
    constructor(props) {
        super(props)
        this.onArrowLeftClick = this.onArrowLeftClick.bind(this)
        this.onArrowRightClick = this.onArrowRightClick.bind(this)

        this.state = {
            firstThumbnail: 0,

            translateXValue: 0,
            windowWidth: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    onArrowLeftClick() {
        this.setState(state => {
            let value = state.translateXValue - (this.state.windowWidth - 20)

            if (value < 0) {
                value = 0
            }

            return {
                translateXValue: value
            }
        })
    }

    onArrowRightClick() {
        this.setState(state => {
            let value = state.translateXValue + (this.state.windowWidth - 20)
            const listWidth = this.props.data.length * 240

            if (value + this.state.windowWidth > listWidth) {
                value = listWidth - this.state.windowWidth
            }

            return {
                translateXValue: value
            }
        })
    }

    onThumbnailClick(video) {
        this.props.onThumbnailClick(video)
    }

    render() {
        const { data } = this.props
        const value = -this.state.translateXValue
        const style = {
            transform: `translateX(${value}px)`
        }
        

        return (
            <div className="youtube-thumbnail-list">
                <div onClick={this.onArrowLeftClick} className="thumbnail-list-arrow thumbnail-list-left">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                </div>
                <div style={style} className="thumbnail-container">
                    {data.map(video => {
                        return <YoutubeThumbnail key={video.id} url={video.thumbnail} title={video.title} subtitle={video.subtitle} onClick={this.onThumbnailClick.bind(this, video)} />
                    })}
                </div>
                <div onClick={this.onArrowRightClick} className="thumbnail-list-arrow thumbnail-list-right">
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }
}

export default YoutubeThumbnailList