import React from 'react'
import Translate from '../containers/Translate'

const BehindTheLens = () => (
    <div className="behind-the-lens">
        <div className="top">
            <h3 className="title">
                <Translate value="btl_title" />
            </h3>
            <div className="subtitle">Hello! We are Emi & Tabita,</div>
            <div className="subtitle">fine art photographer and videographer</div>
        </div>
        <div className="row">
            <div className="widget" />
            <div className="text">
                <h4><Translate value="btl_videographer" /></h4>
                <p><Translate value="btl_videographer_description_1" /></p>
                <p><Translate value="btl_videographer_description_2" /></p>
                <p><Translate value="btl_videographer_description_3" /></p>
                <p><Translate value="btl_videographer_description_4" /></p>
            </div>
            <div className="text">
                <h4><Translate value="btl_photographer" /></h4>
                <p><Translate value="btl_photographer_description_1" /></p>
                <p><Translate value="btl_photographer_description_2" /></p>
                <p><Translate value="btl_photographer_description_3" /></p>
                <p><Translate value="btl_photographer_description_4" /></p>
            </div>
        </div>
    </div>
)

export default BehindTheLens