import React, { useState } from 'react'
import { url } from '../utils/images-repo'
import { IMAGE_DEFAULT, IMAGE_THUMBNAIL } from '../constants/ImageType'

const GallerySlider = props => {
    const [active, setActive] = useState(0)
    const [offset, setOffset] = useState(0)

    const { slug, photos } = props
    if (!slug || !photos) {
        return null
    }

    const sliderWidth = props.sliderWidth || 800

    const activeImage = url(slug, IMAGE_DEFAULT, photos[active])
    const thumbnails = photos.map(photo => url(slug, IMAGE_THUMBNAIL, photo))
    const maxOffset = -thumbnails.length * 70 + sliderWidth

    const style = {
        transform: `translateX(${offset}px)`
    }

    const wrapperStyle = {
        width: sliderWidth
    }

    const onLeftArrowClick = () => {
        setActive(active > 0 ? active - 1 : active)
    }

    return (
        <div className="gallery-slider">
            <div className="gallery-slider-content">
                <div className="gallery-slider-arrow" onClick={onLeftArrowClick}>
                    <i className="arrow arrow-left" />
                </div>
                <div className="gallery-slider-image">
                    <img src={activeImage} alt="" />
                </div>
                <div className="gallery-slider-arrow" onClick={() => { setActive(active < photos.length - 1 ? active + 1 : active) }}>
                    <i className="arrow arrow-right" />
                </div>
            </div>
            <div className="gallery-thumbnails">
                <div className="gallery-arrow-container" onClick={() => { setOffset(offset + sliderWidth / 2 > 0 ? 0 : offset + sliderWidth / 2) }} >
                    <i className="arrow arrow-left" />
                </div>
                <div style={wrapperStyle} className="gallery-slider-thumbnails">
                    <div style={style} className="gallery-slider-thumbnails-container">
                        {thumbnails.map((image, index) => {
                            const style = {
                                backgroundImage: `url(${image})`
                            }

                            return <div key={index} onClick={() => { setActive(index) }} className="thumbnail" style={style} />
                        })}
                    </div>
                </div>
                <div className="gallery-arrow-container" onClick={() => { setOffset(offset - sliderWidth / 2 <= maxOffset ? maxOffset : offset - sliderWidth / 2) }} >
                    <i className="arrow arrow-right" />
                </div>
            </div>
        </div>
    )
}

export default GallerySlider