import React from 'react'
import YoutubePlayer from './YoutubePlayer'
import YoutubeThumbnailList from './YoutubeThumbnailList'

class VideoModal extends React.Component {
    constructor(props) {
        super(props)
        this.onThumbnailClick = this.onThumbnailClick.bind(this)
    }

    onThumbnailClick(video) {
        this.props && this.props.onSelectVideo && this.props.onSelectVideo(video)
    }

    render() {
        const { id, data, onCloseModal } = this.props
        const video = data.find(video => video.id === id)
        if (!video) {
            return null
        }

        return (
            <div className="modal">
                <div className="background"></div>
                <div className="foreground">
                    <div className="foreground-top">
                        <div className="closeButton" onClick={onCloseModal}>
                            <i className="fa fa-times"></i>
                        </div>
                    </div>
                    <div className="foreground-middle">
                        <YoutubePlayer id={video.videoId} />
                    </div>
                    <div className="foreground-bottom">
                        <YoutubeThumbnailList onThumbnailClick={this.onThumbnailClick} data={data} />
                    </div>
                </div>
            </div>
        )
    }


}

export default VideoModal